import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest bolet presenta un capell de fins a 5-6 cm de diàmetre, hemisfèric i després pla, amb el centre de color terrós clar o un poc grogós i la resta blanc. Un poc higròfan, amb fibril·les llargues, lluents i blanquinoses, estriades cap al marge. Davall aquest es troben les làmines lliures i separades de color blanc tornant-se rosades o groguenques per la caiguda de les espores. Aquestes són el·lipsoïdals, de 6,5-7 x 5 micres i el peu és llarg, llis, un poc pubescent i presenta a la base una volva de tres lòbuls, un més gros que els altres dos, de color marró terrós fosc, més blanquinós a la part inferior.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      